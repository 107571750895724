import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import usePostJSON from "../../utils/usePostJson";
import { useNavigate } from "react-router";

export default function AddCustomer() {
  const [info, setInfo] = useState({
    name: "",
    address: "",
    phone: "",
    mail: "",
    newPassword: "",
    reNewPassword: "",
  });

  const { name, address, phone, mail, newPassword, reNewPassword } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const { postData } = usePostJSON();
  const nvigate = useNavigate();
  const addHandeller = async () => {
    const data = {
      name: name,
      email: mail,
      phone: phone,
      password: newPassword,
      password_confirmation: reNewPassword,
      address: address,
      role: "user",
    };
    const result = await postData(`admin/customers/create`, data);
    if (result) {
      nvigate("/custopmers");
    }
  };
  return (
    <div>
      <Layout title={"اضافة عميل"}>
        <div className=" py-8">
          <h1 className=" text-center tiltle my-6"> اضافة عميل</h1>
          <section //personal INFO
            className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
          >
            <div //name
              className=" my-2"
            >
              <h4 className=" inputTitle">الاسم بالكامل</h4>
              <input
                name="name"
                value={name}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder="الاسم بالكامل"
              />
            </div>
            <div //phone
              className=" my-2"
            >
              <h4 className=" inputTitle">رقم الهاتف</h4>
              <input
                name="phone"
                value={phone}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder="رقم الهاتف"
              />
            </div>
            <div //mail
              className=" my-2"
            >
              <h4 className=" inputTitle">البريد الاكتروني</h4>
              <input
                name="mail"
                value={mail}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder="البريد الاكتروني"
              />
            </div>
            <div //address
              className=" my-2"
            >
              <h4 className=" inputTitle">العنوان</h4>
              <input
                name="address"
                value={address}
                onChange={handleInfoChange}
                className=" input"
                type="text"
                placeholder="العنوان"
              />
            </div>
            <div //new
              className=" my-2"
            >
              <h4 className=" inputTitle">كلمة المرور </h4>
              <input
                name="newPassword"
                value={newPassword}
                onChange={handleInfoChange}
                className=" input"
                type="password"
                placeholder="كلمة المرور "
              />
            </div>
            <div //re
              className=" my-2"
            >
              <h4 className=" inputTitle">اعادة كتابة كلمة المرور </h4>
              <input
                name="reNewPassword"
                value={reNewPassword}
                onChange={handleInfoChange}
                className=" input"
                type="password"
                placeholder="اعادة كتابة كلمة المرور   "
              />
            </div>
          </section>
          <section className=" my-4 w-full text-center">
            <button onClick={addHandeller} className="mainBtn">اضافة عميل</button>
          </section>
        </div>
      </Layout>
    </div>
  );
}
