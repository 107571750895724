import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Status from "../../components/pages/Status";
import { Link } from "react-router-dom";
import Coups from "./Caoups";
import useFetch from "../../utils/useGet";

export default function Coupons() {
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch("admin/coupons/all", reload);

  const re = () => {
    setRelaod(!reload);
  };
  return (
    <Layout title="الكوبونات">
      <section>
        <Status
          text={"عدد الكوبونات"}
          num={data ? data.length : 0}
          colour={"fffff"}
        >
          <section
            className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#9C25D7]/20`}
          >
            <i className={`fa-solid fa-ticket text-[20px] text-[#9C25D7]`}></i>
          </section>
        </Status>
        <div className=" flex justify-end">
          <Link to="/add-coupon">
            <button className=" mainBtn ">اضافة كوبون</button>
          </Link>
        </div>
      </section>
      {data && <Coups allData={data} reload={re} />}
    </Layout>
  );
}
