import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import usePost from "../../utils/usePost";
import { useNavigate } from "react-router";

export default function AddDepartment() {
  const [info, setInfo] = useState({
    name: "",
    // colour: "",
    colour: "#0D8F75",
    stores: [],
    file: null,
  });

  const { name, colour, file, stores } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const getSelectedUserNames = (selectedIds) => {
    return allStores
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  const [searchQuery, setSearchQuery] = useState("");

  const allStores = [
    { name: "111", id: 11 },
    { name: "112", id: 12 },
    { name: "113", id: 13 },
    { name: "114", id: 14 },
    { name: "115", id: 15 },
  ];

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = allStores.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Add
  const { postData } = usePost();
  const navigate = useNavigate()
  const addHandeller = async () => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", colour);
    formdata.append("image", file);

    const result = await postData(`admin/sections/create`, formdata);
    if (result) {
      navigate('/')
    }
  };

  return (
    <Layout title={"اضافة قسم"}>
      <div>
        <section //personal INFO
          className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
        >
          <div //name
            className=" my-2"
          >
            <h4 className=" inputTitle">اسم القسم</h4>
            <input
              name="name"
              value={name}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="اسم القسم"
            />
          </div>
          <div //colour
            className=" my-2"
          >
            <h4 className=" inputTitle">لون الخلفية</h4>
            <input
              name="colour"
              value={colour}
              onChange={handleInfoChange}
              className=" input"
              type="color"
              placeholder="قم بكتابة كود لون الخلفية"
            />
          </div>
          {/* <div //colour
            className=" my-2"
          >
            <h4 className=" inputTitle">الوصف</h4>
            <input
              name="colour"
              value={colour}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="قم بكتابة   الوصف"
            />
          </div> */}
          {/* <div //stores
            className=" my-2"
          >
            <h4 className=" inputTitle">المتاجر التابعة للقسم</h4>
            <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={stores}
                className=" input"
                name="stores"
                onChange={handleInfoChange}
                // input={<OutlinedInput label="المستخدمين" />}
                renderValue={(selected) => getSelectedUserNames(selected)}
                // MenuProps={MenuProps}
              >
                <Box sx={{ padding: 1 }}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                {filteredUsers.map((name) => (
                  <MenuItem key={name} value={name.id}>
                    <Checkbox checked={stores.indexOf(name.id) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}
        </section>
        <div className="px-4 md:px-24 lg:px-48">
          <div //image
            className=" my-2  w-full"
          >
            <h4 className=" inputTitle">رفع صورة القسم</h4>
            {file ? (
              // if file or else
              <div className="relative">
                {/* Display Image */}
                <img
                  alt="cover"
                  className="max-w-full max-h-48 border rounded-md shadow-md mx-auto"
                  src={URL.createObjectURL(file)}
                />
              </div>
            ) : (
              <div className="input2 h-[150px] flex items-center justify-center">
                {/* Upload Image */}
                <section>
                  <label
                    className="po cursor-pointer h-full w-full"
                    htmlFor="file"
                  >
                    <span className="w-full flex justify-center">
                      <i className="fa-solid fa-arrow-up-from-bracket w-4 text-[#6D4E1F] text-[18px] text-center"></i>
                    </span>
                    <h4 className="text-[#A3AED0] text-[12px] my-4">
                      قم برفع ملفات PNG , JPG
                    </h4>
                  </label>
                  <input
                    required
                    type="file"
                    id="file"
                    onChange={(e) => {
                      setInfo({ ...info, file: e.target.files[0] });
                    }}
                    style={{ display: "none" }}
                  />
                </section>
              </div>
            )}
          </div>
        </div>
        <section className=" my-4 w-full text-center">
          <button onClick={addHandeller} className="mainBtn">
            انشاء قسم
          </button>
        </section>
      </div>
    </Layout>
  );
}
