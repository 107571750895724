import React from "react";

export default function Status({ text, num, children, status }) {
  return (
    <div className=" m-3 sh-2 bg-white rounded-2xl p-3 w-fit">
      <section className=" w-[220px] flex items-center mb-2 justify-between">
        <section className=" font-sans text-[16px] font-[700] text-[#202224] ">
          <h1 className=" mb-2">{text}</h1>
          <h1>{num}</h1>
        </section>
        <section>{children}</section>
      </section>
      {status && (
        <section className=" text-[#00B69B] text-sm flex items-center justify-start gap-1 ">
        <i className="fa-solid fa-arrow-up-wide-short"></i>
          <p className="">{status}</p>
        </section>
      )}
    </div>
  );
}
