import React, { useContext, useEffect } from "react";
import { headTitle } from "../../utils/titleStore";

export default function Layout({ title, children }) {
  const setTitle = useContext(headTitle).setTitle;
  useEffect(() => {
    setTitle(title);
  });
  return <>{children}</>;
}
