import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import Status from "../../components/pages/Status";
import Supp from "./Supp";
import useFetch from "../../utils/useGet";

export default function Support() {
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch("admin/support/all", reload);

  const re = () => {
    setRelaod(!reload);
  };
  return (
    <div>
      <Layout title={"مركز الدعم"}>
        <section>
          <Status
            text={"عدد المشاكل"}
            num={data ? data.length : 0}
            colour={"fffff"}
          >
            <section
              className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#FFDD8F]/20`}
            >
              <i className={`fa-solid fa-users text-[20px] text-[#FFDD8F]`}></i>
            </section>
          </Status>
        </section>
        {data && <Supp allData={data} reload={re} />}
      </Layout>
    </div>
  );
}
