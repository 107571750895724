import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import usePostJSON from "../../utils/usePostJson";
import { useLocation, useNavigate } from "react-router";

export default function Reply() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [info, setInfo] = useState({
    title: "",
    details: "",
  });
  const { title, details } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    setInfo({ ...info, [name]: value });
  };

  const { postData } = usePostJSON();
  const navigate = useNavigate();

  const replyHandeller = async () => {
    const data = {
      response_title: title,
      response_details: details,
    };

    const result = await postData(`admin/support/${id}/respond`, data);
    if (result) {
      navigate("/support");
    }
  };
  return (
    <Layout title={"الرد علي المشكلة"}>
      <div className="px-4 md:px-24 lg:px-48 my-8">
        <div className=" sh-2 bg-white rounded-xl py-12 px-8 md:px-20 lg:px-40">
          <h1 className="tiltle text-center">الرد علي المشكلة</h1>
          <section className=" my-8">
            <h4 className=" subTitle"> عنوان الرسالة </h4>
            <input
              name="title"
              value={title}
              onChange={handleInfoChange}
              className=" input2"
              type="text"
              placeholder=" عنوان الرسالة"
            />
          </section>
          <section className=" my-8">
            <h4 className=" subTitle"> تفاصيل الرسالة </h4>
            <textarea
              name="details"
              value={details}
              onChange={handleInfoChange}
              className=" input2"
              type="text"
              rows={3}
              placeholder="تفاصيل الرسالة"
            />
          </section>
          <section className=" text-center">
            <button onClick={replyHandeller} className="mainBtn">
              {" "}
              ارسال الرد
            </button>
          </section>
        </div>
      </div>
    </Layout>
  );
}
