import React from "react";

export default function Message({ title, content, close }) {
  return (
    <div className=" bg-black/20 w-full h-screen absolute top-0 left-0 flex items-center justify-center">
      <section className=" text-center p-4 bg-white min-w-[300px] max-w-[70%] rounded-md sh-2">
        <h4 className="tiltle my-4">{title}</h4>
        <p className=" subTitle">{content}</p>

        <button onClick={close} className="secondBtn">
          الغاء
        </button>
      </section>
    </div>
  );
}
