import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function SideBarLink({text , li , icon}) {
  let path = useLocation().pathname;
  return (
    <div className=" my-2">
      <NavLink to={`/${li}`}>
        <div
          className={`  py-2 w-full ${
            path === `/${li}`
              ? "bg-white rounded-l-full shadow-lg text-[#0D8F75]"
              : "hover:bg-black/20 hover:rounded-full"
          }`}
        >
          <i className={`${icon} ml-3 mr-2`}></i>
          {text}
        </div>{" "}
      </NavLink>
    </div>
  );
}
