import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { isLogged } from "../../utils/inStore";

export default function User() {
  const [clicked, setClicked] = useState(false);
  const toggleClick = () => {
    setClicked(!clicked);
  };

  const ref = useRef(null);
  const handelClickOutSide = () => {
    if (clicked) {
      setClicked(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handelClickOutSide && handelClickOutSide();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handelClickOutSide]);

  const setLogg = useContext(isLogged).setLogged;
  const logOutHandeller = () => {
    let token = Cookies.get("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    // fetch(`${process.env.REACT_APP_URL}/api/admin/logout`, requestOptions).then(
    //   (res) => {
    //     if (res.status === 200) {
    //       setLogg(false);
    //       Cookies.remove("token");
    //       Cookies.remove("id");
    //     }
    //   }
    // );

    setLogg(false);
    Cookies.remove("token");
    Cookies.remove("id");
  };

  return (
    <div className=" mx-1 md:mx-4">
      <div className=" relative">
        <button //Drop Down Btn
          onClick={toggleClick}
          className=" flex items-center"
        >
          <h1 className=" text-[#0D8F75] text-[18px] mx-2">ادمن</h1>
          <div className=" bg-[#0D8F75] h-[38px] w-[38px] flex items-center justify-center p-4 rounded-full">
            <i className="fa-solid fa-user-tie text-white text-[22px]"></i>
          </div>
          <i className="fa-solid text-[#0D8F75] mx-2 text-xs fa-chevron-down"></i>
        </button>

        {clicked && (
          <section //Oprions
            ref={ref}
            className=" absolute top-[48px] left-0 rounded-lg overflow-hidden  w-[150px] bg-white shadow-sm"
          >
            <Link to={"/settings"}>
              <p className=" text-[#F47820] px-2 py-4">
                <i class="fa-solid fa-gear"></i> اعدادات الحساب
              </p>
            </Link>
            <hr />
            <button
              onClick={logOutHandeller}
              className=" text-[#FF0000] px-2 py-4"
            >
              <i className="fa-solid fa-right-from-bracket"></i> نسجيل خروج
            </button>
          </section>
        )}
      </div>
    </div>
  );
}
