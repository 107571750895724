import React from "react";
import Notifaction from "./Notifaction";
import User from "./User";

export default function NavBar({ title, toggel }) {
  return (
    <div className=" ">
      <div className=" flex items-center justify-between mb-3 bg-white sh-2 w-full py-4">
        <section className=" flex">
          <button onClick={toggel} className=" lg:hidden mx-6 text-xl">
            <i className="fa-solid fa-bars"></i>
          </button>
          <h1 className=" text-[#0D8F75] text-[24px] font-[700] md:px-12">
            {title}
          </h1>
        </section>
        <section className=" flex items-center">
          {/* <Notifaction /> */}
          <User />
        </section>
      </div>
    </div>
  );
}
