import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import useFetch from "../../utils/useGet";
import usePostJSON from "../../utils/usePostJson";

export default function Notifications() {
  const [sucsses, setSucsses] = useState(false);
  const [notifi, setNotifi] = useState({
    title: "",
    type: "",
    users: [],
    daitails: "",
  });

  const { title, type, daitails, users } = notifi;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setNotifi({ ...notifi, [name]: value });
  };

  const getSelectedUserNames = (selectedIds) => {
    return allUsers
      .filter((user) => selectedIds.includes(user.id))
      .map((user) => user.name)
      .join(", ");
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [allUsers, setUsres] = useState([]);

  const { data } = useFetch("admin/customers/all");

  useEffect(() => {
    if (data) {
      setUsres(data);
    }
  }, [data]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtered users based on search query
  const filteredUsers = allUsers.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { postData } = usePostJSON();
  const sendHandeller = async () => {
    const data = {
      title: title,
      subject: type,
      details: daitails,
      customers: users,
    };

    console.log(data);

    const result = await postData(`admin/notifications/send-notification`, data);
    if (result) {
      setSucsses(true);
      setNotifi({ title: "", type: "", users: [], daitails: "" });
      setTimeout(() => {
        setSucsses(false);
      }, 2000);
    }
  };

  return (
    <Layout title={"ارسال الاشعار"}>
      <div>
        <section //personal INFO
          className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
        >
          <div //name
            className=" my-2"
          >
            <h4 className=" inputTitle">عنوان الاشعار </h4>
            <input
              name="title"
              value={title}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="عنوان الاشعار"
            />
          </div>
          <div //name
            className=" my-2"
          >
            <h4 className=" inputTitle">الموضوع</h4>
            <input
              name="type"
              value={type}
              onChange={handleInfoChange}
              className=" input"
              type="text"
              placeholder="موضوع الاشعار"
            >
              {/* <option selected hidden>
                نوع المستخدم
              </option>
              <option>نوع 1</option>
              <option>نوع 2</option> */}
            </input>
          </div>
        </section>
        <section className="px-4 md:px-24 lg:px-48">
          <div //type
            className=" my-2"
          >
            <h4 className=" inputTitle">المستخدمين</h4>
            <FormControl sx={{ width: "100%" }}>
              {/* <InputLabel id="demo-multiple-checkbox-label">
                المستخدمين
              </InputLabel> */}
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={users}
                className=" w-full  h-[50px] "
                name="users"
                onChange={handleInfoChange}
                // input={<OutlinedInput label="المستخدمين" />}
                renderValue={(selected) => getSelectedUserNames(selected)}
                // MenuProps={MenuProps}
              >
                <Box sx={{ padding: 1 }}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                {filteredUsers.map((name) => (
                  <MenuItem key={name} value={name.id}>
                    <Checkbox checked={users.indexOf(name.id) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div //daitails
            className=" my-2  w-full"
          >
            <h4 className=" inputTitle">تفاصيل الاشعار </h4>
            <textarea
              name="daitails"
              value={daitails}
              onChange={handleInfoChange}
              className="  input2"
              type="text"
              rows={5}
              placeholder="تفاصيل الاشعار"
            />
          </div>
        </section>
        <section className=" my-4 w-full text-center">
          <button onClick={sendHandeller} className="mainBtn">
            ارسال الاشعار
          </button>
        </section>
        {sucsses && (
          <section className=" p-3 bg-green-800 rounded-md sh-2 w-fit text-white mx-auto my-6 text-lg">
            تم الارسال بنجاح
          </section>
        )}
      </div>
    </Layout>
  );
}
