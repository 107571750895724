import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function StoreRequest({ name, img, link, desc, last, phone }) {
  return (
    <div className=" sh-2 m-2">
      <div className="  border-[#E5E5E5] overflow-hidden rounded-xl sh-2">
        <section className=" flex items-center justify-between rounded-xl p-6 bg-[#085646]">
          <section className=" flex items-center">
            <img
              className="h-[80px] w-[80px] rounded-md border-[7px] mx-2 border-white"
              src={img}
              alt="user"
            />
            <section className=" mx-2 text-white">
              <h4 className=" text-[20px] font-[500] text-white">{name}</h4>
            </section>
          </section>
        </section>
        <section className=" max-w-[900px] flex items-center justify-start flex-wrap p-6">
          <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">اسم المتجر</h1>
            <h1 className=" subtext">{name}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">لينك المتجر</h1>
            <a href={link} target="_blank" rel="noreferrer">
              <h1 className=" subtext">{link}</h1>
            </a>
          </div>
          <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">وصف المتجر</h1>
            <h1 className=" subtext">{desc}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">تاريخ الارسال</h1>
            <h1 className=" subtext">{last}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className=" subTitle">رقم التواصل:</h1>
            <h1 className=" subtext">{phone}</h1>
          </div>
        </section>
      </div>
    </div>
  );
}
