import React, { createContext, useState } from "react";

export const headTitle = createContext();

export default function TitleStore({ children }) {
  // const isin = !!Cookies.get("token");
  const [title, setTitle] = useState('');

  return (
    <headTitle.Provider value={{ title, setTitle }}>
      {children}
    </headTitle.Provider>
  );
}
