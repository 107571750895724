import React, { useEffect, useState } from "react";
import useFetch from "../../utils/useGet";
import Cookies from "js-cookie";
import usePutJSON from "../../utils/usePutJson";

export default function Personal() {
  const [sucsses, setSucsses] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    address: "",
    phone: "",
    mail: "",
  });

  const { name, address, phone, mail } = info;
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const id = Cookies.get("id");

  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/settings/show/${id}`, reload);

  useEffect(() => {
    if (data) {
      setInfo({
        name: data.name,
        address: data.address,
        phone: data.phone,
        mail: data.email,
      });
    }
  }, [data]);

  const re = () => {
    setRelaod(!reload);
  };

  const { putData } = usePutJSON();

  const editHandeller = async () => {
    const data = {
      name: name,
      email: mail,
      phone: phone,
      address: address,
    };

    const result = await putData(`admin/settings/update/${id}`, data);
    if (result) {
      await re();
      setSucsses(true);
      setTimeout(() => {
        setSucsses(false);
      }, 2000);
    }
  };
  return (
    <div className="  ">
      <h1 className="tiltle text-center">بيانات المستخدم</h1>
      <section //personal INFO
        className=" px-4 md:px-20 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
      >
        <div //name
          className=" my-2"
        >
          <h4 className=" inputTitle">الاسم بالكامل</h4>
          <input
            name="name"
            value={name}
            onChange={handleInfoChange}
            className=" input"
            type="text"
            placeholder="الاسم بالكامل"
          />
        </div>
        <div //address
          className=" my-2"
        >
          <h4 className=" inputTitle">العنوان</h4>
          <input
            name="address"
            value={address}
            onChange={handleInfoChange}
            className=" input"
            type="text"
            placeholder="العنوان"
          />
        </div>
        <div //phone
          className=" my-2"
        >
          <h4 className=" inputTitle">رقم الهاتف</h4>
          <input
            name="phone"
            value={phone}
            onChange={handleInfoChange}
            className=" input"
            type="text"
            placeholder="رقم الهاتف"
          />
        </div>
        <div //mail
          className=" my-2"
        >
          <h4 className=" inputTitle">البريد الاكتروني</h4>
          <input
            name="mail"
            value={mail}
            onChange={handleInfoChange}
            className=" input"
            type="text"
            placeholder="البريد الاكتروني"
          />
        </div>
      </section>
      <section className=" my-4 w-full text-center">
        <button onClick={editHandeller} className="mainBtn">
          تعديل البيانات
        </button>
        {sucsses && (
          <section className=" p-3 bg-green-800 rounded-md sh-2 w-fit text-white mx-auto my-6 text-lg">
            تم تغير البيانات بنجاح
          </section>
        )}
      </section>
    </div>
  );
}
