import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Customer from "../../components/pages/Customer";
import { useLocation, useNavigate } from "react-router";
import useFetch from "../../utils/useGet";
import StoreRequest from "../../components/pages/StoreRequest";
import usePostJSON from "../../utils/usePostJson";

export default function Request() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("req");
  const [read, setRead] = useState(false);

  const [user, setUser] = useState({
    name: "",
    mail: "",
    last: "",
    phone: "",
    time: "",
    date: "",
  });
  const { name, mail, last, phone, time, date } = user;
  const [store, setStore] = useState({
    link: "",
    storeName: "",
    desc: "",
    img: "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain",
    lastStore: "",
    phoneStore: "",
  });
  const { link, storeName, desc, img, lastStore, phoneStore } = store;

  const { data } = useFetch(`admin/storeOrders/show/${id}`);
  useEffect(() => {
    if (data) {
      if (data.status === "read") {
        setRead(true);
      }
      setUser({
        name: data.user.name,
        mail: data.user.email,
        last: data.user.address,
        phone: data.user.phone,
        time: data.user.created_at.substring(11, 16),
        date: data.user.created_at.substring(0, 10),
      });
      setStore({
        link: data.store_link,
        storeName: data.store_name,
        desc: data.store_description,
        img: `${process.env.REACT_APP_URL}/${data.store_image}`,
        lastStore: data.created_at.substring(0, 10),
        phoneStore: data.contact_number,
      });
    }
  }, [data]);

  const { postData } = usePostJSON();
  const navigate = useNavigate();

  const markRead = async () => {
    const data = {
      status: "read",
    };
    const result = await postData(`admin/storeOrders/markAsRead/${id}`, data);
    if (result) {
      navigate("/requests");
    }
  };
  return (
    <Layout title={" عرض الطلب"}>
      <h1 className="tiltle">تفاصيل العميل</h1>
      <Customer
        time={time}
        mail={mail}
        date={date}
        name={name}
        img="./images/login.jpg"
        last={last}
        phone={phone}
      />
      <h1 className="tiltle">تفاصيل الطلب</h1>
      <StoreRequest
        link={link}
        name={storeName}
        desc={desc}
        img={img}
        last={lastStore}
        phone={phoneStore}
      />
      {!read && (
        <button onClick={markRead} className=" mainBtn">
          تمييز كمقروء
        </button>
      )}
    </Layout>
  );
}
