import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Store from "../../components/pages/Store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CoupsStore from "./Coups";
import useFetch from "../../utils/useGet";
import useDelete from "../../utils/useDelete";
import usePost from "../../utils/usePost";

export default function StroeShow() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("store");
  const [edit, setEdit] = useState(false);

  const [dataHead, setDataHead] = useState({
    img: "https://th.bing.com/th/id/OIP.9hetfdrodOfI9KzE_g_dDAAAAA?rs=1&pid=ImgDetMain",
    name: "",
    date: "",
    phone: "",
    desc: "",
    link: "",
    order: "",
  });
  const { img, name, date, phone, desc, link, order } = dataHead;
  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/stores/show/${id}`, reload);
  const re = () => {
    setRelaod(!reload);
  };
  useEffect(() => {
    if (data) {
      setDataHead({
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        name: data.name,
        date: data.created_at.substring(0, 10),
        phone: data.image_path,
        desc: data.description,
        link: data.image_path,
        order: data.order_store,
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  const { deleteData } = useDelete();

  const nvigate = useNavigate();

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/stores/delete/${id}`);

    if (res) {
      nvigate("/shops");
    }
  };

  const { postData } = usePost();

  const editOrder = async () => {
    const formdata = new FormData();
    formdata.append("order_store", order);
    const result = await postData(`admin/stores/update/${id}`, formdata);
    if (result) {
      re();
    }
  };
  return (
    <div>
      <Layout title={"تفاصيل المتجر"}>
        <div className=" md:flex">
          <div className=" w-full">
            <Store
              link={link}
              name={name}
              desc={desc}
              img={img}
              last={date}
              phone={phone}
            />
            <section className=" flex items-center justify-start gap-2">
              <h1 className=" m-3 text-3xl font-bold ">الترتيب : </h1>
              <input
                className=" text-center bg-[white]/0  outline-none border p-2 text-lg rounded-md"
                type="number"
                value={+order}
                onChange={(e) =>
                  setDataHead({ ...dataHead, order: e.target.value })
                }
              />
              <button onClick={editOrder} className="mainBtn">
                تعديل الترتيب
              </button>
            </section>
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">
              حذف المتجر
            </button>
            {/* <button onClick={() => setEdit(!edit)} className="mainBtn">
              {edit ? "الغاء" : " تعديل المتجر"}
            </button> */}
          </section>
        </div>
        <div>
          <hr className=" my-6"></hr>
          <div className=" flex justify-end">
            <Link to="/add-coupon">
              <button className=" mainBtn ">اضافة كوبون</button>
            </Link>
          </div>

          {data && <CoupsStore allData={data.coupons} reload={re} />}
        </div>
      </Layout>
    </div>
  );
}
