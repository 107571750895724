import React from "react";

export default function SideTab({ tab, icon, handleChange, value, text }) {
  return (
    <div>
      <button
        className={` w-full rounded-l-full text-xl px-1 py-2 my-2 text-start ${
          tab == value ? "bg-[#0D8F75] text-white" : "bg-white text-[#0D8F75]"
        }`}
        onClick={handleChange}
        name={value}
      >
        {text}
      </button>
    </div>
  );
}
