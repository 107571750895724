import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import Customer from "../../components/pages/Customer";
import useFetch from "../../utils/useGet";
import { useLocation, useNavigate } from "react-router";
import usePostJSON from "../../utils/usePostJson";
import useDelete from "../../utils/useDelete";

export default function Customershow() {
  const searchPaeam = useLocation().search;
  const id = new URLSearchParams(searchPaeam).get("customer");
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    address: "",
    phone: "",
    mail: "",
    time: "",
    date: "",
    img: "",
    last: "",
    newPassword: "",
    reNewPassword: "",
  });
  const {
    name,
    address,
    phone,
    mail,
    time,
    date,
    newPassword,
    img,
    last,
    reNewPassword,
  } = info;
  const [infoEdit, setInfoEdit] = useState({
    nameEdit: "",
    addressEdit: "",
    phoneEdit: "",
    mailEdit: "",
    newPasswordEdit: "",
    reNewPasswordEdit: "",
  });

  const {
    nameEdit,
    addressEdit,
    phoneEdit,
    mailEdit,
    newPasswordEdit,
    reNewPasswordEdit,
  } = infoEdit;

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setInfoEdit({ ...infoEdit, [name]: value });
  };

  const [reload, setRelaod] = useState(false);
  const { data } = useFetch(`admin/customers/show/${id}`, reload);
  const re = () => {
    setRelaod(!reload);
  };

  useEffect(() => {
    if (data) {
      setInfo({
        img: `${process.env.REACT_APP_URL}/${data.image_path}`,
        name: data.name,
        address: data.address,
        phone: data.phone,
        mail: data.email,
        time: data.created_at.substring(11, 15),
        date: data.created_at.substring(0, 10),
        last: data.address,
        newPassword: "",
        reNewPassword: "",
      });
      setInfoEdit({
        nameEdit: data.name,
        addressEdit: data.address,
        phoneEdit: data.phone,
        mailEdit: data.email,
        newPasswordEdit: "",
        reNewPasswordEdit: "",
      });
      // setData(data.stores);
      // setForSearch(data.stores);
    }
  }, [data]);

  //Edit

  const { postData } = usePostJSON();
  const editHandeller = async () => {
    const data = {
      name: nameEdit,
      email: mailEdit,
      phone: phoneEdit,
      address: addressEdit,
    };
    const result = await postData(`admin/customers/update/${id}`, data);
    if (result) {
      re();
    }
  };
  const editPasswordHandeller = async () => {
    const data = {
      new_password: newPasswordEdit,
      new_password_confirmation: reNewPasswordEdit,
    };
    const result = await postData(`admin/customers/${id}/updatePassword`, data);
    if (result) {
      re();
    }
  };

  const { deleteData } = useDelete();

  const nvigate = useNavigate();

  const deleteStoreHandeller = async () => {
    let res = await deleteData(`admin/customers/delete/${id}`);

    if (res) {
      nvigate("/custopmers");
    }
  };

  return (
    <div>
      <Layout title={"تفاصيل العميل"}>
        <div className=" md:flex">
          <div className=" w-full">
            <Customer
              time={time}
              mail={mail}
              date={date}
              name={name}
              img={img}
              last={last}
              phone={phone}
            />
          </div>
          <section className=" items-start justify-center flex-wrap h-fit flex md:w-[300px]">
            <button onClick={deleteStoreHandeller} className="deleteBtn">حذف العميل</button>
            <button onClick={() => setEdit(!edit)} className="mainBtn">
              {edit ? "الغاء" : " تعديل الحساب"}
            </button>
          </section>
        </div>
        {edit && (
          <div //edit
          >
            <section //Personal
            >
              <h1 className=" text-center tiltle my-6">معلومات الحساب</h1>
              <section //personal INFO
                className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
              >
                <div //nameEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">الاسم بالكامل</h4>
                  <input
                    name="nameEdit"
                    value={nameEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="text"
                    placeholder="الاسم بالكامل"
                  />
                </div>
                <div //phoneEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">رقم الهاتف</h4>
                  <input
                    name="phoneEdit"
                    value={phoneEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="text"
                    placeholder="رقم الهاتف"
                  />
                </div>
                <div //mailEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">البريد الاكتروني</h4>
                  <input
                    name="mailEdit"
                    value={mailEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="text"
                    placeholder="البريد الاكتروني"
                  />
                </div>
                <div //addressEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">العنوان</h4>
                  <input
                    name="addressEdit"
                    value={addressEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="text"
                    placeholder="العنوان"
                  />
                </div>
              </section>
              <section className=" my-4 w-full text-center">
                <button onClick={editHandeller} className="mainBtn">
                  تعديل البيانات
                </button>
              </section>
            </section>
            <section //passwords
            >
              <h1 className=" text-center tiltle my-6"> معلومات الامان</h1>
              <section //password INFO
                className=" px-4 md:px-24 lg:px-48 my-8 md:flex flex-wrap items-center justify-center md:justify-between"
              >
                <div //newPasswordEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">كلمة المرور </h4>
                  <input
                    name="newPasswordEdit"
                    value={newPasswordEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="password"
                    placeholder="كلمة المرور "
                  />
                </div>
                <div //reNewPasswordEdit
                  className=" my-2"
                >
                  <h4 className=" inputTitle">اعادة كتابة كلمة المرور </h4>
                  <input
                    name="reNewPasswordEdit"
                    value={reNewPasswordEdit}
                    onChange={handleInfoChange}
                    className=" input"
                    type="password"
                    placeholder="اعادة كتابة كلمة المرور   "
                  />
                </div>
              </section>
              <section className=" my-4 w-full text-center">
                <button onClick={editPasswordHandeller} className="mainBtn">
                  {" "}
                  تعديل كلمة المرور
                </button>
              </section>
            </section>
          </div>
        )}
      </Layout>
    </div>
  );
}
