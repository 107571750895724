import { useState, useCallback, useContext } from "react";
import { load } from "./isLoadingStore";
import Cookies from "js-cookie";

const useDelete = () => {
  const setLoading = useContext(load).setIsLoading;
  const [error, setError] = useState(null);

  const deleteData = useCallback(async (endpoint) => {
    setLoading(true);
    setError(null);
    let token = '10|b8MBVdKyoB8weJ23JtErm5YaeTKs4knPc6zlsfjV34f3a085';
    // let token = Cookies.get("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/api/${endpoint}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      return true;
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return { deleteData };
};

export default useDelete;
