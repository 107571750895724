import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function Customer({ name, img, date, time, mail, last, phone }) {
  return (
    <div className=" sh-2 m-2">
      {/* <h1 className="tiltle">تفاصيل العميل</h1> */}
      <div className="  border-[#E5E5E5] overflow-hidden rounded-xl sh-2">
        <section className=" flex items-center justify-between rounded-xl p-6 bg-[#085646]">
          <section className=" flex items-center">
            {/* <img
              className="h-[80px] w-[80px] rounded-md border-[7px] mx-2 border-white"
              src={img}
              alt="user"
            /> */}
            <section className=" mx-2 text-white">
              <h4 className=" mb-1 text-[20px] font-[500] text-white">
                {name}
              </h4>
              <h4 className=" text-xs font-[200]">
                <i className="fa-solid fa-calendar-days ml-1"></i> {date}
                <i className="fa-regular fa-clock mx-1"></i> {time}
              </h4>
            </section>
          </section>
          {/* <section className="hidden md:flex items-center text-white">
            <h4 className=" font-sans font-[500] mx-2">
              معدل اكتمال الملف الشخصي{" "}
            </h4>
            <section className=" h-[100px] mx-4 w-[100px]">
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: `#F4A700`,
                  textColor: "#fff",
                  trailColor: "#fff",
                  backgroundColor: "#fff",
                })}
                value={75}
                text={`${75}%`}
              />
            </section>
          </section> */}
        </section>
        <section className=" max-w-[600px] flex items-center justify-between flex-wrap p-6">
          <div className=" my-4 w-full md:w-1/2">
            <h1 className=" subTitle">اسم العميل</h1>
            <h1 className=" subtext">{name}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2">
            <h1 className=" subTitle">البريد الالكتروني:</h1>
            <h1 className=" subtext">{mail}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2">
            <h1 className=" subTitle">العنوان</h1>
            <h1 className=" subtext">{last}</h1>
          </div>
          <div className=" my-4 w-full md:w-1/2">
            <h1 className=" subTitle"> رقم الهاتف:</h1>
            <h1 className=" subtext">{phone}</h1>
          </div>
        </section>
      </div>
    </div>
  );
}
