import React from "react";
import Status from "../../components/pages/Status";
import useFetch from "../../utils/useGet";

export default function Statuses() {
  const { data } = useFetch("admin/storeOrders/getOrderStatistics");
  return (
    <div>
      <div className=" flex items-center justify-center lg:justify-start flex-wrap">
        <Status
          status={data ? data.total_orders_change : ""}
          text={"جميع الطلبات"}
          num={data ? data.total_orders : 0}
          colour={"fffff"}
        >
          <section
            className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#B007FF]/20`}
          >
            <i
              className={`fa-solid fa-bag-shopping text-[20px] text-[#B007FF]`}
            ></i>
          </section>
        </Status>
        <Status
          status={data ? data.read_orders_change : ""}
          text={"الطلبات المقروءة"}
          num={data ? data.read_orders : 0}
        >
          <section
            className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#0EBBA7]/20`}
          >
            <i
              className={`fa-solid fa-bag-shopping text-[20px] text-[#0EBBA7]`}
            ></i>
          </section>
        </Status>
        <Status
          status={data ? data.unread_orders_change : ""}
          text={"الطلبات الغير مقروءة"}
          num={data ? data.unread_orders : 0}
        >
          <section
            className={` mx-2 rounded-2xl flex items-center justify-center h-[45px] w-[45px] bg-[#FF4501]/20`}
          >
            <i
              className={`fa-solid fa-bag-shopping text-[20px] text-[#FF4501]`}
            ></i>
          </section>
        </Status>
      </div>
    </div>
  );
}
